<template>
    <div>
        <!--탑메뉴-->
        <topbar-comp></topbar-comp>

        <left-bar-comp>
            <sports-left-bar-comp></sports-left-bar-comp>
        </left-bar-comp>

        <div class="content_section">
            <div class="content">
                <sub-title>미니게임 배팅내역</sub-title>
                <bet-info-links></bet-info-links>

                <leisure-bet-list-comp :bet-list="betList"
                                       @deleteBet="deleteBet"
                                       @cancelBet="cancelBet"
                                       @cancelOrDelBet="cancelOrDelBet"
                                       :position="1"
                                       @betInfoChecked="betInfoChecked"></leisure-bet-list-comp>

<!--                    배팅내역에서 게시글작성-->
                        <board-writer
                                :bet-ids="selectedBetIds"
                                :from="1"
                                :btype="sportsConst.GAME_LEISURE" btn-text="선택내역 게시판 등록"></board-writer>

                <pagination :page-index=" pageNum"
                            :total="total"
                            :page-size="pageSize"
                            @change="pageChange"></pagination>
            </div>
        </div>


        <!-- right 메뉴 -->
        <right-bar-comp>
            <!--내정보-->
            <user-info-comp slot="userinfo"></user-info-comp>
            <right-buttons-comp slot="btns"></right-buttons-comp>
            <!--배팅카트-->
            <sports-bet-cart-comp slot="betcart"></sports-bet-cart-comp>
            <right-bar-banner-comp slot="banner"></right-bar-banner-comp>
        </right-bar-comp>

        <!--푸트메뉴-->
        <foot-comp></foot-comp>
    </div>
</template>

<script>
    import RightBarComp from "../../components/RightBarComp";
    import LeftBarComp from "../../components/LeftBarComp";
    import LeisureLeftComp from "../../components/leisuregame/LeisureLeftComp";
    import SubTitle from "../../components/SubTitle";
    import leisureConst from "../../common/leisureConst";
    import sportsConst from "../../common/sportsConst";
    import {deleteById, getLeisureBetList, cancelById} from "../../network/leisureRequest";
    import Pagination from "../../components/pagenation/Pagination";
    import LeisureBetListComp from "../../components/leisuregame/LeisureBetListComp";
    import FootComp from "../../components/FootComp";
    import TopbarComp from "../../components/TopbarComp";
    import RightBarBannerComp from "../../components/RightBarBannerComp";
    import SportsBetCartComp from "../../components/sports/SportsBetCartComp";
    import SportsLeftBarComp from "../../components/sports/SportsLeftBarComp";
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../../store/mutation-types";
    import BoardWriter from "../../components/BoardWriter";
    import {postionMixin} from "../../common/mixin";
    import UserInfoComp from "../../components/UserInfoComp";
    import RightButtonsComp from "../../components/RightButtonsComp";
    import BetInfoLinks from "../../components/BetInfoLinks";

    export default {
        name: "LeisrueGameBetInfo",
        mixins: [postionMixin],
        components: {
            BetInfoLinks,
            RightButtonsComp,
            UserInfoComp,
            BoardWriter,
            SportsLeftBarComp,
            SportsBetCartComp,
            RightBarBannerComp,
            TopbarComp,
            FootComp, LeisureBetListComp, Pagination, SubTitle, LeisureLeftComp, LeftBarComp, RightBarComp
        },
        data() {
            return {
                leisureConst,
                sportsConst,
                pageNum: 1,
                pageSize: 10,
                orderBy: '',
                total: 1,
                search: {betResult: null},
                betList: [],
                selectedBetIds: [],
                position: "M배팅내역",
            }
        },
        methods: {

            /*SportsBetListComp.vue에서 체크박스 클릭시*/
            betInfoChecked(betIds) {
                this.selectedBetIds = betIds
            },

            /*SportsBetListComp.vue에서 취소 또는 삭제시 배팅내역 새로고침*/
            cancelOrDelBet() {
                this.getLeisureBetList()
            },
            getLeisureBetList() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                getLeisureBetList(this.search, this.pageNum, this.pageSize, this.orderBy).then(res => {
                    if (res.data.success) {
                        this.betList = res.data.data
                        this.total = res.data.total
                        //로또일경우
                        this.betList.forEach((game) => {
                            if(game.kind == leisureConst.LEISURE_KIND_BET365_DOGRACE_GOLDENHILLPARK
                                ||game.kind == leisureConst.LEISURE_KIND_BET365_DOGRACE_HILLSIDEPARK
                                ||game.kind == leisureConst.LEISURE_KIND_BET365_BASKETBALL_WARTER
                                ||game.kind == leisureConst.LEISURE_KIND_BET365_BASKETBALL_BAKER
                                ||game.kind == leisureConst.LEISURE_KIND_BET365_SOCCER_WORLDCUP
                                ||game.kind == leisureConst.LEISURE_KIND_BET365_SOCCER_PREMIERSHIP
                                ||game.kind == leisureConst.LEISURE_KIND_BET365_SOCCER_SUPERLEAGUE
                                ||game.kind == leisureConst.LEISURE_KIND_BET365_SOCCER_EUROCUP
                                ||game.kind == leisureConst.LEISURE_KIND_BET365_HORCERACE
                                ||game.kind == leisureConst.LEISURE_KIND_BET365_HORCERACE_2
                                ||game.kind == leisureConst.LEISURE_KIND_BET365_HORCERACE_3
                                ||game.kind == leisureConst.LEISURE_KIND_BET365_HORCERACE_4
                            ){
                                this.$set(game, 'isbet365betting', true);
                            } else {
                                this.$set(game, 'isbet365betting', false);
                            }


                            if (game.attribute === leisureConst.LEISURE_ATTRIBUTE_LOTTO_POWERBALL) {
                                try {
                                    game.selectedValue = JSON.parse(game.selectedValue);
                                    game.selectedValue = game.selectedValue.ball1 + ", " + game.selectedValue.ball2 + ", "
                                        + game.selectedValue.ball3 + ", " + game.selectedValue.ball4 + ", " + game.selectedValue.ball5 + " : "
                                        + game.selectedValue.powerball;
                                    if (game.selectedValueText != null) {
                                        game.selectedValueText = JSON.parse(game.selectedValueText);
                                        game.selectedValueText = game.selectedValueText.res;
                                    }
                                } catch (e) {

                                }
                            }
                        })
                        this.$store.commit(RECEIVE_HIDE_LOADING)
                    }
                })
            },
            cancelBet(id) {
                cancelById(id).then(res => {
                    if (res.data.success) {
                        this.getLeisureBetList()
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            deleteBet(id) {
                deleteById(id).then(res => {
                    if (res.data.success) {
                        this.getLeisureBetList()
                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
            changeBetResult(br) {
                this.search.betResult = br
            },
            pageChange(page) {
                this.pageNum = page
                this.getLeisureBetList()
            }
        },
        watch: {
            search: {
                deep: true,
                handler(newVal) {
                    this.getLeisureBetList()
                },
                immediate: true
            }
        }
    }
</script>

<style scoped>
    @import url("../../assets/css/base.css");
    @import url("../../assets/css/common.css");
    @import url("../../assets/css/style.css");
    @import url("../../assets/css/media.css");
    @import url("../../assets/css/sports.css");
    .bet-info-links {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 10px;
    }

    .bet-info-links button {
        width: 16%;
        line-height: 30px;
        font-size: 12px;
        background: #2d2e35;
        border-radius: 3px;
        transition: 200ms all;
        box-shadow: rgb(0 0 0 / 35%) 0px 3px 4px, rgb(255 255 255 / 22%) 1px 1px 4px -2px inset;
        color: #c8c8c7;
        font-weight: bold;
        margin-top: 3px;
    }

    .bet-info-links .active {
        color: #1c1c25 !important;
        font-size: 14px;
        font-weight: bold;
        background: linear-gradient(rgb(255, 182, 22) 0%, rgb(249, 201, 95) 100%) !important;
    }
</style>